$(document).ready(function(){
	$(window).load(function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		// INITIATIONS
		AOS.init();

		// CUSTOM FUNCTIONS
		carouselAnimation();
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		visitor();
		responsiveSlick();
		videoController();
		inquiryForm();

		$('.loader-overlay').fadeOut(200);

	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').height(),
		$mainWrapper = $('.wrapper-holder');
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );

	});

});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
	equalizer();
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	equalizer();
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	equalizer();
}
else{
}

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/
function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function wrapperHolder( $pageHeight = 0, $navHeight = 0, $footerHeight = 0 ){
	$('.wrapper-holder').css({
		'min-height': $pageHeight - $navHeight,
		'margin-top': $navHeight,
		'padding-bottom': $footerHeight
	});
	$('.main-layout').css({
		'min-height': $pageHeight - ( $navHeight + $footerHeight ),
	});
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" );
		$(this).toggleClass('toggle-mobile-menu');
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(document).on('click', '.mobile-menu-btn', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	var sitelink = $('.usd').data('usdacct');
	document.addEventListener( 'wpcf7mailsent', function( event ) {
	  location = sitelink + '/thank-you/';
	}, false );
}

function locations($country, $state, $city){
	// Sample Field
	// "select.csCountryOption", "select.csStateOption", "select.csCityOption"
	filter_country_state_city($country, $state, $city);
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fas fa-caret-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fas fa-caret-right"></button>',
		});
	});
}

function visitor(){
	$('.visitors').html(function (i, html) {
    var chars = $.trim(html).split("");
    return '<span class="box">' + chars.join('</span><span class="box">') + '</span>';
	});
}

function equalizer(){
	var maxHeight = 0;
	$(".equalizer").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".equalizer").height(maxHeight);

	var titleMaxHeight = 0;
	$(".title-equalizer").each(function(){
	   if ($(this).height() > titleMaxHeight) { titleMaxHeight = $(this).height(); }
	});
	$(".title-equalizer").height(titleMaxHeight);

	var totalHeight = 0;
	var sidebarMaxHeight = $('.archive-sidebar .sidebar').outerHeight();
	var archivePosts = $('.archive-posts').outerHeight();
	if( sidebarMaxHeight > archivePosts ) {
		totalHeight = sidebarMaxHeight;
	} else {
		totalHeight = archivePosts;
	}
	$('.sh-height').height(totalHeight);
}

function videoController(){
	var video = $("#video-pointer");

  // Buttons
  var playButton = $(".play-button");

  $(document).on('click', ".play-button" ,function(){
  	if (video[0].paused == true) {
	    // Play the video
	    video[0].play();

	    // Update the button text to 'Pause'
	    playButton.html('<i class="fas fa-pause"></i>');
	    $('.video-content').toggleClass('played');
	  } else {
	    // Pause the video
	    video[0].pause();

	    // Update the button text to 'Play'
	    playButton.html('<i class="fas fa-play"></i>');
	    $('.video-content').toggleClass('played');
	  }
  });
}

function inquiryForm(){
	$(document).on('click', '.inquiry-btn', function(){
		var name = $(this).attr('data-title');
		$('#inquiry-form .modal-title').text(name);
		$('#inquiry-form .productName input').val(name);
		$('#inquiry-form').modal('show');
	});
}